@import "tooltip.css";

:root {
  --blue: #1a71ff;
  --selected: #0072ff;
  --light-blue: hsl(217, 100%, 91%);
  --dark-blue: hsl(213, 100%, 35%);
  --strong-blue: #1973ff;
  --dark: #113255;
  --pink: hsl(343, 100%, 85%);
  --pink-hover: hsl(343, 63%, 67%);

  --text: #113357;
  --placeholder: #AFB3BF;
  --hover: #E6F1FF;

  --modal-background: #f2f8ff;
  --light-gray: #ebedf3;
}

html,
body {
  padding: 0px;
  margin: 0px !important;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  user-select: none;
  overflow: auto;
  width: 100%;
  min-height: 100vh;

  --cord-primary-button-background-color: hsl(217, 100%, 55%);
  --cord-primary-button-background-color--hover: hsl(213, 100%, 35%);
  --cord-primary-button-content-color: white;
  --cord-thread-border-radius: 0;
  --cord-font-family: "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --cord-color-content-primary: #113255;

  color: var(--dark);

  scrollbar-width: none;
  -ms-overflow-style: none;
}

input,
textarea {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 300;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

* {
  box-sizing: border-box;
}

#modal-container,
#error-page {
  display: none;
  position: absolute;
  inset: 0;
  z-index: 9999;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.spacer {
  flex: 1;
}

.product-switcher-dialog-wrapper {
  z-index: 1000;
}

.uppy-Dashboard-AddFiles-info {
  display: none !important;
}

.curtain {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  z-index: 1000;
}

.checkout {
  display: none;
  position: fixed;
  background: white;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999;
}

.icon {
  vertical-align: middle;
}

*:enabled[role="button"] {
  cursor: pointer;
  pointer-events: all;
  /* todo: it was wrong to add this line, can I safely remove it?*/
}

.separator {
  flex:0 0 1px;
  height: 1px;
  background-color: var(--light-gray);
  width: 100%;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: 3px solid transparent;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/*
  for all svg children, where svg is also of class "autocenter"
  make sure the transform origin is at the center, so all rotations and scalings
  are done from the center.
*/
svg .autocenter {
  transform-box: fill-box;
  transform-origin: center;
}

.simplebar-scrollbar::before {
  background-color: #d9d9d9 !important;
  border-radius: 50px;
  width: 5px;
}

#ui-widgets > *:not(:last-child) {
  opacity: 0.3;
  transition: opacity 0.2s;
}

#ui-widgets:last-child {
  opacity: 1;
}
