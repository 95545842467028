*:has(> .tooltip) {
  position: relative;
}

*:has(> .tooltip):is(:hover, :focus-visible, :active) > .tooltip {
  opacity: 1;
  /* uncomment this to make tooltips appear after delay */
  /* transition-delay: 100ms; */
}

.tooltip {
  --_p-inline: 15px;
  --_p-block: 7px;
  --_triangle-size: 9px;
  --_bg: var(--dark);
  --_shadow-alpha: 50%;

  --_bottom-tip: conic-gradient(from -45deg at bottom, rgba(0, 0, 0, 0), #000 1deg 90deg, rgba(0, 0, 0, 0) 91deg) bottom /
    100% 50% no-repeat;
  --_top-tip: conic-gradient(from 135deg at top, rgba(0, 0, 0, 0), #000 1deg 90deg, rgba(0, 0, 0, 0) 91deg) top / 100%
    50% no-repeat;
  --_right-tip: conic-gradient(from -120deg at right, rgba(0, 0, 0, 0), #000 1deg 90deg, rgba(0, 0, 0, 0) 91deg) right /
    50% 100% no-repeat;
  --_left-tip: conic-gradient(from 45deg at left, rgba(0, 0, 0, 0), #000 1deg 90deg, rgba(0, 0, 0, 0) 91deg) left / 50%
    100% no-repeat;

  pointer-events: none;
  user-select: none;

  opacity: 0;
  transform: translateX(var(--_x, 0)) translateY(var(--_y, 0));
  transition: opacity 0.2s ease, transform 0.2s ease;

  position: absolute;
  z-index: 1;
  inline-size: max-content;
  max-inline-size: 25ch;
  text-align: start;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  -webkit-font-smoothing: subpixel-antialiased;
  padding: var(--_p-block) var(--_p-inline);
  margin: 0;
  background: var(--_bg);
  color: white !important;
  border-radius: 2px;
}

/* tooltip shape is a pseudo element so we can cast a shadow */
.tooltip::after {
  content: "";
  background: var(--_bg);
  position: absolute;
  z-index: -1;
  inset: 0;
  mask: var(--_tip);
}

/* top tooltip styles */
.tooltip:is([tip-position="top"], [tip-position="block-start"], :not([tip-position]), [tip-position="bottom"], [tip-position="block-end"]) {
  text-align: center;
}

.tooltip:is([tip-position="top"], [tip-position="block-start"], :not([tip-position])) {
  inset-inline-start: 50%;
  inset-block-end: calc(100% + var(--_p-block) + var(--_triangle-size));
  --_x: calc(-50%);
}

.tooltip:is([tip-position="top"], [tip-position="block-start"], :not([tip-position]))::after {
  --_tip: var(--_bottom-tip);
  inset-block-end: calc(var(--_triangle-size) * -1);
  border-block-end: var(--_triangle-size) solid transparent;
}

.tooltip:is([tip-position="right"], [tip-position="inline-end"]) {
  inset-inline-start: calc(100% + var(--_p-inline) + var(--_triangle-size));
  inset-block-end: 50%;
  --_y: 50%;
}

.tooltip:is([tip-position="right"], [tip-position="inline-end"])::after {
  --_tip: var(--_left-tip);
  inset-inline-start: calc(var(--_triangle-size) * -1);
  border-inline-start: var(--_triangle-size) solid transparent;
}

.tooltip:is([tip-position="right"], [tip-position="inline-end"]):dir(rtl)::after {
  --_tip: var(--_right-tip);
}

.tooltip:is([tip-position="bottom"], [tip-position="block-end"]) {
  inset-inline-start: 50%;
  inset-block-start: calc(100% + var(--_p-block) + var(--_triangle-size));
  --_x: calc(-50%);
}

.tooltip:is([tip-position="bottom"], [tip-position="block-end"])::after {
  --_tip: var(--_top-tip);
  inset-block-start: calc(var(--_triangle-size) * -1);
  border-block-start: var(--_triangle-size) solid transparent;
}

.tooltip:is([tip-position="left"], [tip-position="inline-start"]) {
  inset-inline-end: calc(100% + var(--_p-inline) + var(--_triangle-size));
  inset-block-end: 50%;
  --_y: 50%;
}

.tooltip:is([tip-position="left"], [tip-position="inline-start"])::after {
  --_tip: var(--_right-tip);
  inset-inline-end: calc(var(--_triangle-size) * -1);
  border-inline-end: var(--_triangle-size) solid transparent;
}

.tooltip:is([tip-position="left"], [tip-position="inline-start"]):dir(rtl)::after {
  --_tip: var(--_left-tip);
}

.tooltip:is([tip-position="left"], [tip-position="inline-start"]) {
  inset-inline-end: calc(100% + var(--_p-inline) + var(--_triangle-size));
  inset-block-end: 50%;
  --_y: 50%;
}

@media (prefers-reduced-motion: no-preference) {
  :has(> .tooltip:is([tip-position="top"], [tip-position="block-start"], :not([tip-position]))):not(:hover):not(:focus-visible):not(:active)
    .tooltip {
    --_y: 3px;
  }

  :has(> .tooltip:is([tip-position="right"], [tip-position="inline-end"])):not(:hover):not(:focus-visible):not(:active)
    .tooltip {
    --_x: -3px;
  }

  :has(> .tooltip:is([tip-position="bottom"], [tip-position="block-end"])):not(:hover):not(:focus-visible):not(:active)
    .tooltip {
    --_y: -3px;
  }

  :has(> .tooltip:is([tip-position="left"], [tip-position="inline-start"])):not(:hover):not(:focus-visible):not(:active)
    .tooltip {
    --_x: 3px;
  }
}
